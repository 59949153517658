import React, { useState } from "react";

import userIcon from '../assets/images/icons/user-light-icon.svg'
import teamIcon from '../assets/images/icons/team-icon.svg'
import fileIcon from '../assets/images/icons/file-icon-light.svg'
import logoutIcon from '../assets/images/icons/logout-light-icon.svg'
import avatarIcon from '../assets/images/icons/avatar-icon.svg'
import SettingTeamsComponent from "../components/SettingTeamsComponent";
import EditUserRole from "../components/modals/EditUserRole";
import AddUserModal from "../components/modals/AddUserModal";
import SettingAuditLogComponent from "../components/SettingAuditLogComponent";

export default function Settings() {


    const [editRoleModal, setEditRoleModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [settingsTab, setSettingsTab] = useState('profile');

    const settingsTabHandler = (tab) => {
        setSettingsTab(tab)
    }
    const editRoleModalOpenHandler = () => {
        setEditRoleModal(true)
    }
    const editRoleModalCloseHandler = () => {
        setEditRoleModal(false)
    }
    const addUserModalOpenHandler = () => {
        setAddUserModal(true)
    }
    const addUserModalCloseHandler = () => {
        setAddUserModal(false)
    }

    return (
        <div className="settings-page">
            <div className="container">
                <div className="title mb-4">
                    <h3 className="dark large">Settings</h3>
                </div>
                <div className="settings-inner-section">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="side-tabs-main-wrapper">
                                <div className="side-tabs">
                                    <ul className="tabs-list">
                                        <li><a href="#" onClick={() => settingsTabHandler('profile')} className={`${settingsTab === 'profile' ? 'active' : ''}`}><img src={userIcon} alt="userIcon" /> Profile </a></li>
                                        <li><a href="#" onClick={() => settingsTabHandler('teams')} className={`${settingsTab === 'teams' ? 'active' : ''}`}><img src={teamIcon} alt="teamIcon" /> Teams </a></li>
                                        <li><a href="#" onClick={() => settingsTabHandler('auditLog')} className={`${settingsTab === 'auditLog' ? 'active' : ''}`}><img src={fileIcon} alt="fileIcon" /> Audit Log </a></li>
                                    </ul>
                                </div>
                                <div className="logout-btns">
                                    <ul className="tabs-list">
                                        <li><a href="#"><img src={logoutIcon} alt="logoutIcon" />  Log out</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="setting-tabs-main-content-wrapper">
                                <div className="tab-title-wrapper">
                                    <strong className="large dark">Account</strong>
                                    <button className="btn-style" onClick={addUserModalOpenHandler}> Add User</button>
                                </div>
                                <div className="inner-body-wrapper">
                                    {settingsTab === 'profile' &&
                                        <div className="account-tab-content-wrapper">
                                            <form action="#" className="form">
                                                <div className="profile-pic-wrapper">
                                                    <label htmlFor="#">Profile Photo</label>
                                                    <div className="profile-image">
                                                        <img src={avatarIcon} alt="avatarIcon" className="avatar-pic" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="field-wrapper">
                                                            <div className="form-group">
                                                                <label htmlFor="name">First name</label>
                                                                <input type="text" className="form-control" placeholder="John" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="field-wrapper">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Last name</label>
                                                                <input type="text" className="form-control" placeholder="Doe" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="field-wrapper">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Email</label>
                                                                <input type="text" className="form-control" placeholder="email@example.com" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-btn-wrapper">
                                                    <button className="btn-style blue-btn">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                    {settingsTab === 'teams' &&
                                        <div className="teams-tab">
                                            <SettingTeamsComponent editRoleModalOpenHandler={editRoleModalOpenHandler} />
                                        </div>
                                    }
                                    {settingsTab === 'auditLog' &&
                                        <div className="audit-log-tab">
                                            <SettingAuditLogComponent />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditUserRole editRoleModalCloseHandler={editRoleModalCloseHandler} editRoleModal={editRoleModal} />
            <AddUserModal addUserModalCloseHandler={addUserModalCloseHandler} addUserModal={addUserModal} />
        </div>
    )
}