export const basePath = process.env.REACT_APP_BASE_URL || 'http://localhost:8000/api/v1'
export const assetsBasePath = process.env.REACT_APP_ASSET_BASE_URL || (basePath.split('/api')[0] + '/uploads/')
// export const assetsBasePath = 'https://pub-fa961811248b42dfb280445c23415b13.r2.dev/'

export const APIurls = {
    register: '/auth/register',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    updatePassword: '/auth/update-password',
    login: '/auth/login',
    logout: '/auth/logout',
    profile: '/auth/profile',
    authenticate: '/auth/authenticate',
    refreshToken: '/auth/refresh-tokens',
    users: '/users',
    creator: '/creators',
    creatorPerformance: 'https://partnerapi.brandsmeetcreators.xyz/fetch-partners-stats',
    createJob: '/jobs',
    creatorProfile: '/creators/profile',
    brand: '/brands',
    myBrand: '/brands/auth',
    brandProfile: '/brands/profile',
    brandAgreement: '/brands/agreement',
    payment: '/payments',
    jobs: '/jobs',
    applyJob: '/jobs/application',
    applications: '/applications',
    myJobs: '/jobs/auth',
    jobCounts: '/jobs/auth/count',
    applicationCreatorCounts: '/applications/creator/count',
    myJobsRelated: '/jobs/auth/related',
    availableJobs: '/jobs/available',
    recommendedJobs: '/jobs/recommended',
    hiredJobs: '/jobs/hired-jobs',
    contracts: '/pandadoc',
    chat: '/chat',
    invite: '/invitation',
    // tiktok auth
    tiktokAuthInfo: '/tiktok/auth/info',
    tiktokAuthCallback: '/tiktok/auth/callback',
    tiktokTargetCollaborations: '/tiktok/affiliate_seller/target-collaborations',
    tiktokGetSampleApplication: '/tiktok/affiliate_seller/sample/applications/search',
    tiktokGetSampleApplicationFulfillment: '/tiktok/affiliate_seller/sample/applications/fulfillment',
    
    // analytics
    tiktokGetAnalyticsShopPerformance: '/tiktok/affiliate_seller/analytics/shop-performance',
    tiktokGetAnalyticsShopProductPerformance: '/tiktok/affiliate_seller/analytics/shop-product-performance',
    tiktokGetAnalyticsShopVideoPerformance: '/tiktok/affiliate_seller/analytics/shop-video-performance',
}
