import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import envelopIcon from "../assets/images/icons/envelop-icon.svg";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import Logo from "../assets/images/icons/logo-white.svg";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import settingsLightIcon from "../assets/images/icons/settings-light-icon.svg";
import helpLightIcon from "../assets/images/icons/help-light-icon.svg";
import logoutIcon from "../assets/images/icons/logout-light-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import NotificationComponent from "./NotificationsComponent";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from "../redux/pagesSlices/authSlice";

export default function DashboardHeader({}) {
  const [showNotification, setShowNotifications] = useState(false);
  const dispatch = useDispatch();

  const showNotificationsHandler = () => {
    setShowNotifications(true);
  };
  const hideNotificationsHandler = () => {
    setShowNotifications(false);
  };

  const handleLogout = () => {
    dispatch(userLogoutAsyncThunk());
  };

  return (
    <header className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 align-self-center">
            <div className="logo-wrapper">
              <a href="#">
                <img src={Logo} alt="Logo" className="logo" />
              </a>
            </div>
            <div className="header-menu">
              <ul className="menu">
                <li>
                  <NavLink end to="/dashboard" activeClassName="active">
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink end to="/dashboard/jobs" activeClassName="active">
                    Jobs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    end
                    to="/dashboard/creators"
                    activeClassName="active"
                  >
                    Creators
                  </NavLink>
                </li>
                <li>
                  <NavLink end to="/dashboard/brands" activeClassName="active">
                    Brands
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <div className="right-menu">
              <ul className="menu">
                <li className={`has-notification`}>
                  <Link
                    to="#"
                    onClick={showNotificationsHandler}
                    className={`${showNotification ? "active" : ""}`}
                  >
                    <img src={notificationIcon} alt="notificationIcon" />
                  </Link>
                </li>
              </ul>
              <div className="avatar-dropdown-wrapper">
                <img src={avatarIcon} alt="avatarIcon" className="avatar-img" />
                <DropdownButton
                  id={`dropdown-button-drop-down`}
                  drop={"down"}
                  variant="secondary"
                  className="custom-dropdown"
                  title={"Ahsan"}
                >
                  <Dropdown.Item
                    eventKey="1"
                    as={Link}
                    to="/dashboard/my-profile"
                  >
                    <img src={shopIcon} alt="shopIcon" /> My brands{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    as={Link}
                    to="/dashboard/my-profile"
                  >
                    <img src={userLightIcon} alt="userLightIcon" /> View brand
                    profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    as={Link}
                    to="/dashboard/settings"
                  >
                    <img src={settingsLightIcon} alt="userLightIcon" /> Settings
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4">
                    <img src={helpLightIcon} alt="userLightIcon" /> Help &
                    Support
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="4" onClick={handleLogout}>
                    <img src={logoutIcon} alt="userLightIcon" /> Log out
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationComponent
        hideNotificationsHandler={hideNotificationsHandler}
        showNotification={showNotification}
      />
    </header>
  );
}
