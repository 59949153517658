import React, { memo, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import shopIcon from "../../assets/images/icons/shop-icon.svg";
import targetIcon from "../../assets/images/icons/target-icon.svg";
import refreshIcon from "../../assets/images/icons/refresh-icon-blue.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { dateFormat } from "../../utils/format";
import { getId } from "../../utils/utils";

const CreatorCardComponent = memo(({ item = {} }) => {
  return (
    <tr>
      <td>
        <div className="main-wrapper user-info">
          <div className="img-wrapper">
            <Link to={`/dashboard/creator-detail/${getId(item)}`}>
              <img
                src={item?.avatar ? parseImagePathToURL(item?.avatar) : JobsImg}
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to={`/dashboard/creator-detail/${getId(item)}`}>
                {item?.name || item?.userName}
              </Link>
            </strong>
            <span className="username">{item?.email}</span>
          </div>
        </div>
      </td>
      <td>
        <p className="dark">{toTitleCase(item?.gender)}</p>
      </td>
      <td>
        <p className="dark">{item?.jobsApplied}</p>{" "}
      </td>
      <td>
        <p className="dark">{item?.steps}</p>{" "}
      </td>
      <td>
        <p className="dark">{item?.isJsonLoaded ? "Yes" : "No"}</p>{" "}
      </td>
      <td>
        <p className="dark">
          {item?.createdAt
            ? dateFormat(item?.createdAt)
            : dateFormat(item?.updatedAt)}
        </p>{" "}
      </td>
      <td>
        <p className={`dark status-text ${item?.status}`}>
          {toTitleCase(item?.status)}
        </p>{" "}
      </td>
      <td>
        <div className="action-btn-wrapper">
          <Link to={`/dashboard/creator-detail/${getId(item)}`}>
            <div className="icon-wrapper rounded medium">
              <img src={refreshIcon} alt="refreshIcon" />
            </div>
          </Link>
          <Link to={`/dashboard/creator-detail/${getId(item)}`}>
            <div className="icon-wrapper rounded medium">
              <img src={eyeIcon} alt="eyeIcon" />
            </div>
          </Link>
          <DropdownButton
            id={`dropdown-button-drop-down`}
            drop={"down"}
            variant="secondary"
            className="custom-dropdown three-dots-line"
            title={<PiDotsThreeOutlineVerticalFill />}
          >
            <div className="title">
              <strong className="dark">Action</strong>
            </div>
            <Dropdown.Item eventKey="1" as={Link}>
              <img src={eye} alt="eye" /> View job details
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" as={Link} href="">
              <img src={targetIcon} alt="targetIcon" /> Track proposal
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <img src={shopIcon} alt="shopIcon" /> View brand profile
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
    </tr>
  );
});

export default CreatorCardComponent;
