import React, { memo, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import shopIcon from "../../assets/images/icons/shop-icon.svg";
import targetIcon from "../../assets/images/icons/target-icon.svg";
import refreshIcon from "../../assets/images/icons/refresh-icon-blue.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { dateFormat } from "../../utils/format";
import { getId, setValueIfExist } from "../../utils/utils";
import { useDispatch } from "react-redux";
import {
  getJobsAsyncThunk,
  getJobsCountAsyncThunk,
  updateJobAsyncThunk,
} from "../../redux/pagesSlices/jobSlice";

const JobCardComponent = memo(({ item = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApproveJob = (jobId) => {
    dispatch(
      updateJobAsyncThunk({
        id: jobId,
        data: { status: "approved" },
        callBack: () => {
          const params = {
            ...setValueIfExist({
              populate: "brandId",
              status: "pending",
            }),
          };

          dispatch(getJobsAsyncThunk(params));
          dispatch(getJobsCountAsyncThunk({}));
        },
      })
    );
  };

  return (
    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to={`/dashboard/job-detail/${getId(item)}`}>
              <img
                src={
                  item?.image?.length > 0
                    ? parseImagePathToURL(item?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to={`/dashboard/job-detail/${getId(item)}`}>
                {item?.jobTitle}
              </Link>
            </strong>
          </div>
        </div>
      </td>
      <td>
        <p className="dark">{item?.applicantsCount}</p>{" "}
      </td>
      <td>
        <p className="dark">{item?.brandId?.userName || item?.brandName}</p>{" "}
      </td>
      <td>
        <p className={`dark status-text ${item?.status}`}>
          {toTitleCase(item?.status)}
        </p>
      </td>
      <td>
        <p className="dark">{dateFormat(item?.createdAt)}</p>{" "}
      </td>
      <td>
        <div className="action-btn-wrapper">
          <Link to={`/dashboard/job-detail/${getId(item)}`}>
            <div className="icon-wrapper rounded medium">
              <img src={eyeIcon} alt="eyeIcon" />
            </div>
          </Link>
          <DropdownButton
            id={`dropdown-button-drop-down`}
            drop={"down"}
            variant="secondary"
            className="custom-dropdown three-dots-line"
            title={<PiDotsThreeOutlineVerticalFill />}
          >
            {item?.status === "pending" && (
              <Dropdown.Item
                eventKey="1"
                onClick={() => handleApproveJob(getId(item))}
              >
                Approve Job
              </Dropdown.Item>
            )}
            <Dropdown.Item
              eventKey="1"
              onClick={() => navigate(`/dashboard/job-detail/${getId(item)}`)}
            >
              View job details
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
    </tr>
  );
});

export default JobCardComponent;
