import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import TitleAndFilterBar from "../components/TitleAndFilterBar";
import { parseImagePathToURL } from "../utils/globalFunctions";

import JobsImg from "../assets/images/job-img-1.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import ViewProfileDetailModal from "../components/modals/ViewProfileDetailModal";
import { useDispatch, useSelector } from "react-redux";
import { getJobAsyncThunk } from "../redux/pagesSlices/jobSlice";
import {
  getJobApplicantsAsyncThunk,
  getJobApplicationsAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { dateFormat } from "../utils/format";
import { getId, returnArray } from "../utils/utils";
import { toTitleCase } from "../utils/methods";

export default function JobDetailPage() {
  const { jobId } = useParams();
  const { job } = useSelector((state) => state.job);
  const { jobApplications } = useSelector((state) => state.application);
  const [showModel, setShowModel] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (jobId) {
      dispatch(getJobAsyncThunk(jobId));
    }
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      dispatch(
        getJobApplicationsAsyncThunk({
          id: jobId,
          params: { populate: "creatorId", name },
        })
      );
    }
  }, [jobId, name]);

  return (
    <div className="job-detail-page">
      <div className="container">
        <div className="detail-page-main-title-wrapper">
          <div className="inner-wrapper">
            <div className="upper-section-wrapper">
              <div className="row">
                <div className="col-md-7 align-self-center">
                  <div className="content-wrapper">
                    <div className="back-btn-wrapper">
                      <Link to="/dashboard">
                        <MdOutlineKeyboardArrowLeft />
                        <strong className="medium-bold">Back to jobs</strong>
                      </Link>
                    </div>
                    <div className="main-title-wrapper">
                      <h3>{job?.jobTitle}</h3>
                      <div className="main-wrapper">
                        <div className="title">
                          <p>
                            Posted: <span>{dateFormat(job?.createdAt)}</span>
                          </p>
                        </div>
                        <div className="title">
                          <p>
                            Deadline: <span>10/02/2024 </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 align-self-center">
                  <div className="btn-wrapper">
                    <a
                      href="#"
                      className="btn-style blue-border"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModel(true);
                      }}
                    >
                      <img src={jobFileIcon} alt="jobFileIcon" /> View job
                      details
                    </a>
                    <div className="icon-wrapper">
                      <a
                        href="#"
                        className="d-flex align-items-center justify-content-center"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <BsThreeDots />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="job-detail-section">
          <TitleAndFilterBar name={name} setName={setName} />
          <div className="listings-section">
            <div className="table-wrapper">
              <table className="gapped">
                <thead>
                  <tr>
                    <th>
                      <span className="white">Name</span>
                    </th>
                    <th>
                      <span className="white">Date applied</span>
                    </th>
                    <th>
                      <span className="white">Status</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {returnArray(jobApplications?.results).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="main-wrapper user-info">
                            <div className="img-wrapper">
                              <Link
                                to={`/dashboard/creator-detail/${getId(
                                  item?.creatorId
                                )}`}
                              >
                                <img
                                  src={
                                    item?.creatorId?.avatar
                                      ? parseImagePathToURL(
                                          item?.creatorId?.avatar
                                        )
                                      : JobsImg
                                  }
                                  alt="JobsImg"
                                />
                              </Link>
                            </div>
                            <div className="text-wrapper">
                              <strong className="dark semi_bold">
                                <Link
                                  to={`/dashboard/creator-detail/${getId(
                                    item?.creatorId
                                  )}`}
                                >
                                  {item?.creatorId?.name ||
                                    item?.creatorId?.userName}
                                </Link>
                              </strong>
                              <span className="username">
                                {" "}
                                @{item?.creatorId?.tikTokUserName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="dark">{dateFormat(item?.createdAt)}</p>{" "}
                        </td>
                        <td>
                          <p className={`dark status-text ${item?.status}`}>
                            {toTitleCase(item?.status)}
                          </p>{" "}
                        </td>
                        <td>
                          <div className="action-btn-wrapper">
                            <Link to={`#`}>
                              <div className="icon-wrapper rounded medium">
                                <img src={eyeIcon} alt="eyeIcon" />
                              </div>
                            </Link>
                            <DropdownButton
                              id={`dropdown-button-drop-down`}
                              drop={"down"}
                              variant="secondary"
                              className="custom-dropdown three-dots-line"
                              title={<PiDotsThreeOutlineVerticalFill />}
                            >
                              <div className="title">
                                <strong className="dark">Action</strong>
                              </div>
                              <Dropdown.Item eventKey="1" as={Link}>
                                <img src={eye} alt="eye" /> View job details
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="1" as={Link} href="">
                                <img src={targetIcon} alt="targetIcon" /> Track
                                proposal
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="2">
                                <img src={shopIcon} alt="shopIcon" /> View brand
                                profile
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ViewProfileDetailModal
        viewProfileModal={showModel}
        viewProfileModalCloseHandler={() => setShowModel(false)}
      />
    </div>
  );
}
