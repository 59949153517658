import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import profileImg from "../../assets/images/icons/avatar-icon.svg";
import proposalImg from "../../assets/images/proposal-img.svg";
import countryIcon from "../../assets/images/icons/map-icon.svg";
import dollarSign from "../../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../../assets/images/icons/regroup-icon.svg";
import profileBagIcon from "../../assets/images/icons/shopping-bag-icon.svg";
import message from "../../assets/images/icons/sms-icon.svg";
import { CiHeart } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

function ViewProfileDetailModal({
  viewProfileModalCloseHandler = () => { },
  viewProfileModal,
}) {


  return (
    <div className="container">
      <Modal
        show={viewProfileModal}
        onHide={viewProfileModalCloseHandler}
        className="view-profile-short-info-modal"
        centered
      >
        <Modal.Header>
          <Button
            variant="close"
            onClick={viewProfileModalCloseHandler}
            aria-label="Close"
            style={{ marginLeft: 0 }}
            className="cancel-btn "
          ></Button>
          <Link
            className="m-0 blue"
            to={`#`}
          >
            View full Profile
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div className="top-profile-box">
            <div className="profile-info">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="profile-left">
                    <img src={profileImg} alt="" className="profile-img" />
                    <div className="profile-text">
                      <h5>
                        Mirza Ahsan
                      </h5>
                      <p>@mmahsan</p>
                      <div className="profile-icons">
                        <div className="profile-text-icon">
                          <img src={countryIcon} alt="" />
                          <p>United State</p>
                        </div>
                        <div className="profile-text-icon">
                          <img src={profileBagIcon} alt="" />
                          <p>48 jobs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="profile-btn">
                    <button>
                      <img
                        src={message}
                        alt=""
                        style={{ color: "white" }}
                      />
                      Message Creator
                    </button>
                    <div className="heart-icon">
                      <CiHeart fontSize={25} />
                    </div>
                    <div className="three-dot-icon">
                      <BsThreeDots fontSize={20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="top-profile-bottom-text">
              <div className="profile-bottom-text">
                <p>Estimated ROI</p>
                <span>3.5X</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV (last 28 days)</p>
                <span>$500</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV per video</p>
                <span>$900</span>
              </div>
            </div>
          </div>
          <div className="profile-center-box">
            <div className="main-center-profile">
              <div className="center-text">
                <h1>Proposal details</h1>
                <h6>What makes you unique for this job?</h6>
                <p>Bio Here</p>
              </div>
            </div>

            <div className="image-container">
              <strong className="dark semi_bold">Recent Works</strong>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="image-side-text-parent">
                    <img src={proposalImg} alt="png img" />
                    <div className="text-icon-parent">
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={regroupIcon} alt="Regrouplight icon" />
                          <span className="roi-text">ROI</span>
                        </div>
                        <span className="text-3x">3x</span>
                      </div>
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={dollarSign} alt="dollarSign" />
                          <span className="roi-text">GMV</span>
                        </div>
                        <span className="text-3x">$89</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="image-side-text-parent">
                    <img src={proposalImg} alt="png img" />
                    <div className="text-icon-parent">
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={regroupIcon} alt="Regrouplight icon" />
                          <span className="roi-text">ROI</span>
                        </div>
                        <span className="text-3x">3x</span>
                      </div>
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={dollarSign} alt="dollarSign" />
                          <span className="roi-text">GMV</span>
                        </div>
                        <span className="text-3x">$89</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="image-side-text-parent">
                    <img src={proposalImg} alt="png img" />
                    <div className="text-icon-parent">
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={regroupIcon} alt="Regrouplight icon" />
                          <span className="roi-text">ROI</span>
                        </div>
                        <span className="text-3x">3x</span>
                      </div>
                      <div className="text-icon">
                        <div className="inner-text-icon">
                          <img src={dollarSign} alt="dollarSign" />
                          <span className="roi-text">GMV</span>
                        </div>
                        <span className="text-3x">$89</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bordered-box-text">
                <div className="video-prices">
                  <h4>Live prices</h4>
                  <div class="price-option">
                    <div className="price-card-sec">
                      <div class="price-card">
                        <span className="alpha-text">
                          5 Live/m
                        </span>
                        <span className="dollar-text">
                          250
                        </span>
                      </div>
                    </div>
                    <div className="price-card-sec"></div>
                  </div>
                </div>
                <div className="video-prices">
                  <h4>Video prices</h4>
                  <div class="price-option">
                    <div className="price-card-sec">
                      <div class="price-card">
                        <span className="alpha-text">
                          5 Live/m
                        </span>
                        <span className="dollar-text">
                          250
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-price-cards">
              <strong className="dark semi_bold">Price Videos</strong>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="price-cards">
                    <p>5 Videos per month</p>
                    <h1>$240</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-price-cards live-cards">
              <h6>Price Lives</h6>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="price-cards">
                    <p>5 Live per month</p>
                    <h1>$100</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div crlassName="bottom-box">
            <h6>Brand note</h6>
            <p>
              Hey, is this your best prices for videos? we have a deal if you
              can come down to $200
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewProfileDetailModal;
