import React from "react";

export default function PageTitleHeader({ page }) {
  return (
    <div className="page-title-header-component">
      <div className={`container ex-large `}>
        <div className="row">
          <div className="col-md-4 align-self-center">
            <div className="inner-page-title-wrapper">
              <h2>{page || "Jobs"}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
