import React, { useEffect, useState } from "react";
import PageTitleHeader from "../components/PageTitleHeader";

import TabsAndFilterBarCreator from "../components/TabsAndFilterBarCreator";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import {
  getCreatorsAsyncThunk,
  getCreatorsCountAsyncThunk,
} from "../redux/pagesSlices/creatorSlice";
import CreatorCardComponent from "../components/creators/CreatorCardComponent";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import { setValueIfExist } from "../utils/utils";
import { useDispatch } from "react-redux";

export default function CreatorsPage() {
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [activeTab, setActiveTab] = useState("all");
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      ...setValueIfExist({
        name: search,
        sortBy,
      }),
    };
    if (activeTab !== "all") {
      params.status = activeTab;
    }
    setTimeout(() => {
      dispatch(getCreatorsAsyncThunk(params));
    }, 500);
  }, [search, dispatch, activeTab, sortBy]);

  useEffect(() => {
    const params = {
      ...setValueIfExist({
        name: search,
      }),
    };
    setTimeout(() => {
      dispatch(getCreatorsCountAsyncThunk(params));
    }, 500);
  }, [search, dispatch]);

  return (
    <div className="jobs-page">
      <PageTitleHeader page={"Creators"} />
      <TabsAndFilterBarCreator
        // exLargeContainer={true}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setSearch={setSearch}
        search={search}
        sortBy={sortBy}
        setSortBy={setSortBy}
        largeContainer={true}
      />
      <div className="job-listings-section">
        <div className="container ex-large">
          <div className="listing-main-wrapper">
            <div className="table-wrapper">
              <table className="gapped">
                <thead>
                  <tr>
                    <th style={{ paddingLeft: 120 }}>
                      <span className="white medium-bold">Creator name</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Gender</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Jobs</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Steps</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Stats loaded</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Member since</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Status</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ProviderErrorLoadingPagination
                    reducer="creator"
                    action="getCreatorsAsyncThunk"
                    asyncThunk={getCreatorsAsyncThunk}
                    dataKey="creators"
                    Component={CreatorCardComponent}
                    emptyMessage={
                      <>
                        <NoCreatorFoundComponent />
                      </>
                    }
                    componentProps={{
                      className: "col-md-4",
                    }}
                    pagination
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
