import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";

import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import logotiktokIcon from "../assets/images/icons/logotiktok.svg";
import logofacebookIcon from "../assets/images/icons/logofacebook.svg";
import logoinstagramIcon from "../assets/images/icons/logoinstagram.svg";
import logoytIcon from "../assets/images/icons/logoyt.svg";
import bagIcon from "../assets/images/icons/bag-icon-fill.svg";
import userIcon from "../assets/images/icons/user-fill-icon.svg";
import imgBocIcon from "../assets/images/icons/img-box-icon.svg";
import chartIconIcon from "../assets/images/icons/chart-icon.svg";
import serviceIcon from "../assets/images/icons/service-icon.svg";
import gearIcon from "../assets/images/icons/settings-icon.svg";
import barsIcon from "../assets/images/icons/bars-icon.svg";
import JobsImg from "../assets/images/job-img-1.svg";
import CreatorProfileOverViewTab from "../components/CreatorProfileOverViewTab";
import CreatorUniquenessComponent from "../components/CreatorUniquenessComponent";
import CreatorPortfolioTab from "../components/CreatorPortfolioTab";
import CreatorAnalyticsTab from "../components/CreatorAnalyticsTab";
import DashboardTrendsChartComponent from "../components/DashboardTrendsChartComponent";
import TrendChartComponent from '../components/TrendChartComponent';
import CreatorProfileServiceOfferedTab from "../components/CreatorProfileServiceOfferedTab";
import PreferencesActivityLog from "../components/PreferencesActivityLog";
import PaymentAndSubscriptions from "../components/PaymentAndSubscriptions";

export default function BrandDetailPage() {

    const [creatorProfileTab, setCreatorProfileTab] = useState('jobs')

    const creatorProfileTabHandler = (tab) => {
        setCreatorProfileTab(tab)
    }
    return (
        <div className="creator-detail-page">
            <div className="container">
                <div className="page-upper-section">
                    <div className="row">
                        <div className="col-md-4 align-self-center">
                            <div className="back-btn-wrapper">
                                <Link to="/dashboard">
                                    <MdOutlineKeyboardArrowLeft />
                                    <strong className="medium-bold">Back to jobs</strong>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8 align-self-center">
                            <div className="btn-wrapper">
                                <a
                                    href="#"
                                    className="btn-style blue-border"
                                >
                                    <img src={jobFileIcon} alt="jobFileIcon" /> Suspend creator
                                </a>
                                <div className="icon-wrapper">
                                    <a
                                        href="#"
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ width: "100%", height: "100%" }}
                                    >
                                        <BsThreeDots />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator-profile-info-section">
                    <div className="basic-info-wrapper">
                        <div className="creator-name-info mb-3">
                            <div className="profile-img-wrapper ">
                                <img src={avatarIcon} alt="avatarIcon" className="profile-img" />
                            </div>
                            <div className="text-wrapper mt-3">
                                <strong className="dark medium d-block">Burger King</strong>
                                <strong ><Link to="#" style={{ fontWeight: 500 }} >burgerking@geniusmindzone.com</Link></strong>
                            </div>
                        </div>
                        <div className="personal-info-wrapper">
                            <div className="text">
                                <span>Location</span>
                                <strong>United States</strong>
                            </div>
                            <div className="text">
                                <span>Language</span>
                                <strong>English </strong>
                            </div>
                            <div className="text">
                                <span>Type</span>
                                <strong>Brand </strong>
                            </div>
                            <div className="text">
                                <span>Member Since</span>
                                <strong>09/22/2024 </strong>
                            </div>
                        </div>
                        <div className="social-icons">
                            <ul className="social-icons-list">
                                <li><Link to="#"><img src={logotiktokIcon} alt="logotiktokIcon" /></Link></li>
                                <li><Link to="#"><img src={logofacebookIcon} alt="logotiktokIcon" /></Link></li>
                                <li><Link to="#"><img src={logoinstagramIcon} alt="logotiktokIcon" /></Link></li>
                                <li><Link to="#"><img src={logoytIcon} alt="logotiktokIcon" /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="creator-profile-main-content-section">
                        <div className="tabs-main-wrapper">
                            <ul className="tabs-wrapper justify-content-start gap-4">
                                <li><Link to="#" className={`${creatorProfileTab == 'jobs' ? 'active' : ''}`} onClick={() => creatorProfileTabHandler('jobs')} ><img src={bagIcon} alt="bagIcon" /> Jobs </Link></li>
                                <li><Link to="#" className={`${creatorProfileTab == 'profileOverview' ? 'active' : ''}`} onClick={() => creatorProfileTabHandler('profileOverview')} ><img src={userIcon} alt="bagIcon" /> Profile overview</Link></li>
                                <li><Link to="#" className={`${creatorProfileTab == 'payments' ? 'active' : ''}`} onClick={() => creatorProfileTabHandler('payments')} ><img src={imgBocIcon} alt="bagIcon" /> Payments & Subscription </Link></li>
                                <li><Link to="#" className={`${creatorProfileTab == 'preferences' ? 'active' : ''}`} onClick={() => creatorProfileTabHandler('preferences')} ><img src={gearIcon} alt="bagIcon" /> Preference</Link></li>
                            </ul>
                        </div>

                        <div className="inner-section ">
                            {creatorProfileTab === 'jobs' &&
                                <>
                                    <div className="creator-basic-info-cards-main-wrapper">
                                        <div className="row">
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <div className="info-card-wrapper">
                                                    <div className="img-wrapper">
                                                        <img src={barsIcon} alt="barsIcon" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="medium-bold">Jobs posted</p>
                                                        <strong className="large dark">8</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <div className="info-card-wrapper">
                                                    <div className="img-wrapper">
                                                        <img src={barsIcon} alt="barsIcon" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="medium-bold">Completed</p>
                                                        <strong className="large dark">8</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <div className="info-card-wrapper">
                                                    <div className="img-wrapper">
                                                        <img src={barsIcon} alt="barsIcon" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="medium-bold">Failed</p>
                                                        <strong className="large dark">8</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <div className="info-card-wrapper">
                                                    <div className="img-wrapper">
                                                        <img src={barsIcon} alt="barsIcon" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="medium-bold">Success rate</p>
                                                        <strong className="large dark">8</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="jobs-section shadowed-wrapper">
                                        <div className="section-title-with-cards">
                                            <strong className="semi_bold dark large">Jobs</strong>
                                            <div className="nav-tabs-main-wrapper">
                                                <ul className="tabs-wrapper gray_bg">
                                                    <li className="active"><a href="#">Current</a></li>
                                                    <li><a href="#">History</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="table-wrapper">
                                            <table className="bottom-bordered">
                                                <thead>
                                                    <tr>
                                                        <th ><span className="white medium-bold">Name</span></th>
                                                        <th><span className="white medium-bold">Creator</span></th>
                                                        <th><span className="white medium-bold">Status</span></th>
                                                        <th><span className="white medium-bold">Start</span></th>
                                                        <th><span className="white medium-bold">End</span></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="main-wrapper">
                                                                <div className="img-wrapper">
                                                                    <Link to="/dashboard/brand-detail">
                                                                        <img src={JobsImg} alt="JobsImg" />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-wrapper">
                                                                    <strong className="dark semi_bold"><Link to="/dashboard/brand-detail">Cocacola</Link></strong>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="main-wrapper ex-small">
                                                                <div className="img-wrapper">
                                                                    <Link to="/dashboard/job-detail">
                                                                        <img src={JobsImg} alt="JobsImg" />
                                                                    </Link>
                                                                </div>
                                                                <div className="text-wrapper">
                                                                    <strong className="dark semi_bold"><Link to="/dashboard/creator-detail">Burger King</Link></strong>
                                                                </div>
                                                            </div>  </td>
                                                        <td><p className="dark status-text pending">Pending</p>  </td>
                                                        <td><p className="dark">09/27/2024</p>  </td>
                                                        <td><p className="dark">09/27/2024</p>  </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            }
                            {creatorProfileTab === 'profileOverview' &&
                                <>
                                    <div className="creator-unique-wrapper shadowed-wrapper">
                                        <CreatorUniquenessComponent />
                                    </div>
                                </>
                            }
                            {creatorProfileTab === 'payments' &&
                                <>
                                    <div className="payments-and-subscriptions-content shadowed-wrapper">
                                        <PaymentAndSubscriptions />
                                    </div>
                                </>
                            }
                            {creatorProfileTab === 'preferences' &&
                                <>
                                    <div className="preferences-content shadowed-wrapper">
                                        <div className="preferences-basic-info-wrapper">
                                            <div className="section-title-with-cards">
                                                <strong className="semi_bold dark large">Preference</strong>
                                            </div>
                                            <div className="basic-information">
                                                <div className="list-wrapper">
                                                    <p className="medium medium-bold" >Preferred Communication</p>
                                                    <strong className="medium semi_bold dark" >Email, Sms</strong>
                                                </div>
                                                <div className="list-wrapper">
                                                    <p className="medium medium-bold" >Push notification </p>
                                                    <strong className="medium semi_bold dark" > Enabled </strong>
                                                </div>
                                                <div className="list-wrapper">
                                                    <p className="medium medium-bold" >Time zone </p>
                                                    <strong className="medium semi_bold dark" >UTC - 06:00 Central Time (US & Canada)</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="activity-log-content shadowed-wrapper">
                                        <PreferencesActivityLog />
                                    </div>
                                </>
                            }
                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}