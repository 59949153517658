import React from "react";

import masterCard from '../assets/images/icons/master-card-icon.svg'
import { TfiDownload } from "react-icons/tfi";

export default function PaymentAndSubscriptions() {
    return (
        <div className="payment-andsubscriptions-main-wrapper">
            <div className="title mb-4">
                <strong className="dark large">Payment method</strong>
            </div>
            <div className="payment-methhod-wrapper">
                <div className="card-icon-wrapper">
                    <div className="icon">
                        <img src={masterCard} alt="masterCard" />
                    </div>
                    <div className="text-wrapper">
                        <strong className="dark medium semi_bold d-block">Mastercard</strong>
                        <strong className="d-bock medium">*****124</strong>
                    </div>
                </div>
                <div className="date">
                    <p className="medium-bold mb-0">Exp. Date 06/10</p>
                </div>
            </div>
            <div className="title mb-4">
                <strong className="dark large">Billing history </strong>
            </div>
            <div className="table-wrapper">
                <table className="simple-light-table simple">
                    <thead>
                        <tr>
                            <th><span className="dark">Date</span></th>
                            <th><span className="dark">Amount</span></th>
                            <th><span className="dark">Description</span></th>
                            <th><span className="dark">Method</span></th>
                            <th style={{textAlign : 'end'}}><span className="dark">Action</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p>January 3,2024</p></td>
                            <td><p>$2000</p></td>
                            <td><p>Membership subscription</p></td>
                            <td><p>Card </p></td>
                            <td style={{textAlign : 'end'}}><button className="custom-btn trasparent-btn blue"><TfiDownload  style={{width:18 , height:20, color : '#578BFC'}} /></button></td>
                        </tr>
                        <tr>
                            <td><p>January 3,2024</p></td>
                            <td><p>$2000</p></td>
                            <td><p>Membership subscription</p></td>
                            <td><p>Card </p></td>
                            <td style={{textAlign : 'end'}}><button className="custom-btn trasparent-btn blue"><TfiDownload  style={{width:18 , height:20, color : '#578BFC'}} /></button></td>
                        </tr>
                        <tr>
                            <td><p>January 3,2024</p></td>
                            <td><p>$2000</p></td>
                            <td><p>Membership subscription</p></td>
                            <td><p>Card </p></td>
                            <td style={{textAlign : 'end'}}><button className="custom-btn trasparent-btn blue"><TfiDownload  style={{width:18 , height:20, color : '#578BFC'}} /></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}