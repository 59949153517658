import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import filterIcon from "../assets/images/icons/filter-icon.svg";
import listIcon from "../assets/images/icons/list-icon.svg";
import gridIcon from "../assets/images/icons/grid-icon.svg";

const filterLabels = {
  "createdAt:desc": "Newest First",
  "createdAt:asc": "Oldest First",
  "jobTitle:asc": "A-Z",
  "jobTitle:desc": "Z-A",
};

export default function TabsAndFilterBar({
  statusTabs,
  exLargeContainer,
  largeContainer,
}) {
  return (
    <div className="tabs-and-filter-bar-main-wrapper">
      <div
        className={`container ex-large ${
          largeContainer ? "large" : ""
        }`}
      >
        <div className="row">
          <div className="col-md-7 align-self-center">
            <div className="nav-tabs-main-wrapper">
              <ul className="tabs-wrapper gray_bg d-inline-block">
                <li className={`active`}>
                  <a href="#" >
                    Approved (0)
                  </a>
                </li>
                <li className={``}>
                  <a href="#" >
                    Pending (0)
                  </a>
                </li>
                <li className={``}>
                  <a href="#" >
                    Draft (0)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
              <div className="search-bar-wrapper">
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="search-icon"
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Jobs"
                />
              </div>
              <div className="filter-and-view-wrapper">
                <div className="sort-by-wrapper">
                  <strong>Sort by: </strong>
                  <DropdownButton
                    id={`dropdown-button-drop-down`}
                    drop={"down"}
                    variant="secondary"
                    title={'Newest'}
                  >
                    <Dropdown.Item eventKey="createdAt:desc">
                      Newest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt:asc">
                      Oldest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                  </DropdownButton>
                  {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}