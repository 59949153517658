import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";

// Start Job Slices
///////////////////////////////////////////////////

export const getJobsAsyncThunk = createAsyncThunk(
  "job/getJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getJobs(params);
    return response?.data;
  })
);

export const getJobsCountAsyncThunk = createAsyncThunk(
  "job/getJobsCountAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAllJobCounts(params);
    return response?.data;
  })
);

export const getJobsByBrandAsyncThunk = createAsyncThunk(
  "job/getJobsByBrandAsyncThunk",
  catchAsync(async ({ id, params }, _) => {
    const response = await ApiRequests.getJobsByBrand(id, params);
    return response?.data;
  })
);
export const getAvailableJobsByBrandAsyncThunk = createAsyncThunk(
  "job/getAvailableJobsByBrandAsyncThunk",
  catchAsync(async ({ id, params }, _) => {
    const response = await ApiRequests.getAvailableJobsByBrand(id, params);
    return response?.data;
  })
);

export const getMyJobsAsyncThunk = createAsyncThunk(
  "job/getMyJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyJobs(params);
    return response?.data;
  })
);

export const getMyAllJobsAsyncThunk = createAsyncThunk(
  "job/getMyAllJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyAllJobs(params);
    return response?.data;
  })
);

export const getMyJobRelatedAsyncThunk = createAsyncThunk(
  "job/getMyJobRelatedAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyJobRelated(params);
    return response?.data;
  })
);

export const getAvailableJobsAsyncThunk = createAsyncThunk(
  "job/getAvailableJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getAvailableJobs(params);
    return response?.data;
  })
);
export const getRecommendedJobsAsyncThunk = createAsyncThunk(
  "job/getRecommendedJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getRecommendedJobs(params);
    return response?.data;
  })
);

export const getHiredJobsAsyncThunk = createAsyncThunk(
  "job/getHiredJobsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getHiredJobs(params);
    return response?.data;
  })
);

export const getJobAsyncThunk = createAsyncThunk(
  "job/getJobAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getJob(id);
    return response?.data;
  })
);

export const getJobDetailsAsyncThunk = createAsyncThunk(
  "job/getJobDetailsAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getJobDetails(id);
    return response?.data;
  })
);
export const applyJobAsyncThunk = createAsyncThunk(
  "job/applyJobAsyncThunk",
  catchAsync(async ({ id, data, callBack = () => {} }, _) => {
    const response = await ApiRequests.applyJob(id, data);
    toast.success(
      "Job Applied Successfully! Please read the agreement and click the Agree button to proceed."
    );
    if (typeof callBack === "function") callBack(response?.data);
    return response?.data;
  })
);

export const createJobAsyncThunk = createAsyncThunk(
  "job/createJobAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.createJob(data);
    if (response.status == 204) {
      toast.success("Job Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(getJobsAsyncThunk({ ...params, populate: "job_id", role: "Job" }));
    return response?.data;
  })
);

export const updateJobAsyncThunk = createAsyncThunk(
  "job/updateJobAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.updateJob({ id, data });
    if (response.status == 200) {
      toast.success("Job Updated Successfully!");
    }
    if (callBack) callBack();
    // let params = {};
    // let state1 = getState().listings;
    // if (state1.search) params.name = state1.search;
    // if (state1.order) params.sortBy = `name:${state1.order}`;
    // dispatch(getJobsAsyncThunk({ ...params, populate: "brandId" }));
    // dispatch(getJobsByIdsAsyncThunk({ populate: "image,job_id", ...state.jobs?.paramsForThunk?.getJobsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const reviewJobAsyncThunk = createAsyncThunk(
  "job/reviewJobAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.reviewJob({ id, data });
    if (response.status == 204) {
      toast.success("Job Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteJobAsyncThunk = createAsyncThunk(
  "job/deleteJobAsyncThunk",
  catchAsync(async ({ id, callBack = () => {} }, _) => {
    const response = await ApiRequests.deleteJob(id);
    if (response.status == 204) {
      if (callBack && typeof callBack === "function") callBack();
      toast.success("Job Deleted Successfully!");
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

export const getJobCountsAsyncThunk = createAsyncThunk(
  "job/getJobCountsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getJobCounts(params);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  jobs: {
    page: 1,
    jobs: [],
    results: [],
    totalPages: 1,
  },
  jobsByBrand: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  availableJobsByBrand: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  myJobs: {
    page: 1,
    jobs: [],
    results: [],
    totalPages: 1,
  },
  myAllJobs: [],
  myJobsRelated: {
    page: 1,
    jobs: [],
    results: [],
    totalPages: 1,
  },
  availableJobs: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  recommendedJobs: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  hiredJobs: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  createJobs: {
    page: 1,
    jobs: [],
    totalPages: 1,
  },
  jobsCount: {},
  inviteJob: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  jobExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  jobRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  jobsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  job: null,
  appliedJob: null,
  jobDetails: {
    job: {},
    application: null,
  },
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  allJobsCount: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(createJobAsyncThunk.fulfilled, (state, action) => {
        state.createJobs = action.payload;
      })

      .addCase(getJobsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.jobs = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getJobsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.jobs = {
            ...action.payload,
            jobs: state?.jobs?.results.concat(action?.payload?.results),
          };
        } else {
          state.jobs = action.payload;
        }
      })
      .addCase(getJobsByBrandAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.jobsByBrand = {
            ...action.payload,
            results: state?.jobsByBrand?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.jobsByBrand = action.payload;
        }
      })
      .addCase(getAvailableJobsByBrandAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.availableJobsByBrand = {
            ...action.payload,
            results: state?.availableJobsByBrand?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.availableJobsByBrand = action.payload;
        }
      })
      .addCase(getMyJobsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.myJobs = {
            ...action.payload,
            jobs: state?.myJobs?.results.concat(action?.payload?.results),
          };
        } else {
          state.myJobs = action.payload;
        }
      })
      .addCase(getMyAllJobsAsyncThunk.fulfilled, (state, action) => {
        state.myAllJobs = action.payload;
      })
      .addCase(getMyJobRelatedAsyncThunk.fulfilled, (state, action) => {
        state.myJobsRelated = action.payload;
      })
      .addCase(getJobCountsAsyncThunk.pending, (state, action) => {
        state.jobsCount = {};
      })
      .addCase(getJobCountsAsyncThunk.fulfilled, (state, action) => {
        state.jobsCount = action.payload;
      })
      .addCase(getAvailableJobsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.availableJobs = {
            ...action.payload,
            jobs: state?.availableJobs?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.availableJobs = action.payload;
        }
      })
      .addCase(getRecommendedJobsAsyncThunk.fulfilled, (state, action) => {
        state.recommendedJobs = action.payload;
      })
      .addCase(getHiredJobsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.hiredJobs = {
            ...action.payload,
            results: state?.hiredJobs?.results.concat(action?.payload?.results),
          };
        } else {
          state.hiredJobs = action.payload;
        }
      })
      .addCase(getJobAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.job = {
            ...action.payload,
            results: state?.job?.results.concat(action?.payload?.results),
          };
        } else {
          state.job = action.payload;
        }
      })
      .addCase(getJobDetailsAsyncThunk.pending, (state, action) => {
        state.jobDetails = {};
        state.appliedJob = {};
      })
      .addCase(getJobDetailsAsyncThunk.fulfilled, (state, action) => {
        state.jobDetails = action.payload;
      })
      .addCase(applyJobAsyncThunk.fulfilled, (state, action) => {
        state.appliedJob = action.payload;
      })
      .addCase(getJobsCountAsyncThunk.fulfilled, (state, action) => {
        state.allJobsCount = action.payload;
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getJobsAsyncThunk,
            getJobAsyncThunk,
            getJobDetailsAsyncThunk,
            deleteJobAsyncThunk,
            createJobAsyncThunk,
            getMyJobsAsyncThunk,
            getMyJobRelatedAsyncThunk,
            updateJobAsyncThunk,
            reviewJobAsyncThunk,
            applyJobAsyncThunk,
            getJobsByBrandAsyncThunk,
            getAvailableJobsByBrandAsyncThunk,
            getRecommendedJobsAsyncThunk,
            getAvailableJobsAsyncThunk,
            getJobCountsAsyncThunk,
            getMyAllJobsAsyncThunk,
            getJobsCountAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default jobSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  jobSlice.actions;
