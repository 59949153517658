import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../components/DashboardeHeader";

export default function Dashboard() {

  return (
    <div className="dashboard-page">
      <DashboardHeader />
      <Outlet />
    </div>
  );
}
