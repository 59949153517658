import React from "react";
import { Link } from "react-router-dom";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import PageTitleHeader from "../components/PageTitleHeader";
import TabsAndFilterBar from "../components/TabsAndFilterBar";

import JobsImg from "../assets/images/job-img-1.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import refreshIcon from "../assets/images/icons/refresh-icon-blue.svg";

export default function BrandsPage() {
  return (
    <div className="jobs-page">
      <PageTitleHeader page={"Brands"} />
      <TabsAndFilterBar
        // exLargeContainer={true}
        largeContainer={true}
      />
      <div className="job-listings-section">
        <div className="container ex-large">
          <div className="listing-main-wrapper">
            <div className="table-wrapper">
              <table className="gapped">
                <thead>
                  <tr>
                    <th style={{ paddingLeft: 120 }}>
                      <span className="white medium-bold">Brand</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Website</span>
                    </th>
                    <th>
                      <span className="white medium-bold">No. of Jobs</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Member since</span>
                    </th>
                    <th>
                      <span className="white medium-bold">Status</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="main-wrapper user-info">
                        <div className="img-wrapper">
                          <Link to="/dashboard/brand-detail">
                            <img src={JobsImg} alt="JobsImg" />
                          </Link>
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark semi_bold">
                            <Link to="/dashboard/brand-detail">
                              GreenSprout
                            </Link>
                          </strong>
                          <span className="username">hannah23@gmail.com</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="dark">
                        <Link to="#">https://getlivfresh.com</Link>
                      </p>{" "}
                    </td>
                    <td>
                      <p className="dark">5</p>{" "}
                    </td>
                    <td>
                      <p className="dark">09/27/2024</p>{" "}
                    </td>
                    <td>
                      <p className="dark status-text pending">Active</p>{" "}
                    </td>
                    <td>
                      <div className="action-btn-wrapper">
                        <Link to={`/dashboard/job-detail`}>
                          <div className="icon-wrapper rounded medium">
                            <img src={eyeIcon} alt="eyeIcon" />
                          </div>
                        </Link>
                        <DropdownButton
                          id={`dropdown-button-drop-down`}
                          drop={"down"}
                          variant="secondary"
                          className="custom-dropdown three-dots-line"
                          title={<PiDotsThreeOutlineVerticalFill />}
                        >
                          <div className="title">
                            <strong className="dark">Action</strong>
                          </div>
                          <Dropdown.Item eventKey="1" as={Link}>
                            <img src={eye} alt="eye" /> View job details
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1" as={Link} href="">
                            <img src={targetIcon} alt="targetIcon" /> Track
                            proposal
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="2">
                            <img src={shopIcon} alt="shopIcon" /> View brand
                            profile
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="main-wrapper user-info">
                        <div className="img-wrapper">
                          <Link to="/dashboard/creator-detail">
                            <img src={JobsImg} alt="JobsImg" />
                          </Link>
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark semi_bold">
                            <Link to="/dashboard/brand-detail">
                              GreenSprout
                            </Link>
                          </strong>
                          <span className="username">hannah23@gmail.com</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="dark">
                        <Link to="#">https://getlivfresh.com</Link>
                      </p>{" "}
                    </td>
                    <td>
                      <p className="dark">5</p>{" "}
                    </td>
                    <td>
                      <p className="dark">09/27/2024</p>{" "}
                    </td>
                    <td>
                      <p className="dark status-text pending">Active</p>{" "}
                    </td>
                    <td>
                      <div className="action-btn-wrapper">
                        <Link to={`/dashboard/job-detail`}>
                          <div className="icon-wrapper rounded medium">
                            <img src={eyeIcon} alt="eyeIcon" />
                          </div>
                        </Link>
                        <DropdownButton
                          id={`dropdown-button-drop-down`}
                          drop={"down"}
                          variant="secondary"
                          className="custom-dropdown three-dots-line"
                          title={<PiDotsThreeOutlineVerticalFill />}
                        >
                          <div className="title">
                            <strong className="dark">Action</strong>
                          </div>
                          <Dropdown.Item eventKey="1" as={Link}>
                            <img src={eye} alt="eye" /> View job details
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1" as={Link} href="">
                            <img src={targetIcon} alt="targetIcon" /> Track
                            proposal
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="2">
                            <img src={shopIcon} alt="shopIcon" /> View brand
                            profile
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
