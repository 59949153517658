import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";

export default function AddUserModal({addUserModalCloseHandler, addUserModal}) {
    return (
        <div className="add-user-modal-wrapper">
            <Modal
                show={addUserModal}
                onHide={addUserModalCloseHandler}
                className="custom-modal edit-role-modal"
                centered
            >
                <Modal.Header>
                    
                    <strong className="dark large">New User</strong>
                    <Button
                        variant="close"
                        onClick={addUserModalCloseHandler}
                        aria-label="Close"
                        style={{ marginLeft: 0 }}
                        className="cancel-btn "
                    ></Button>
                </Modal.Header>

                <Modal.Body>
                    <div className="inner-body-wrapper">
                        <form action="#" className="form">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="first-name">Email</label>
                                            <input type="text" className="form-control" placeholder="lionelmessi23@gmail.com" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="first-name">Role</label>
                                            <DropdownButton
                                                id={`dropdown-button-drop-down`}
                                                drop={"down"}
                                                variant="secondary"
                                                className="custom-dropdown"
                                                title={'Manager'}
                                            >
                                                <Dropdown.Item
                                                    eventKey="1"
                                                    as={Link}
                                                    to="/dashboard/my-profile"
                                                >
                                                    Manager
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    eventKey="2"
                                                    as={Link}
                                                    to="/dashboard/my-profile"
                                                >
                                                    Manager
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end">
                                <a href="#" className="btn-style transparent auto_width" onClick={addUserModalCloseHandler}>Cancel</a>
                                <a href="#" className="btn-style auto_width">Save</a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}